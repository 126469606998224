<template>
    <modal :id="id" title="Delete Media" max-width="700" @open="onOpen">
        <v-row v-if="isLoading" class="my-0 py-6">
            <v-col class="my-6 py-6 text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    class="mr-2"
                />
                Checking media usage...
            </v-col>
        </v-row>
        <v-row v-else justify="center">
            <v-col class="px-1 pb-0">
                <div v-if="canDeleteAll" class="py-12">
                    <v-row dense class="mb-4">
                        <v-col cols="auto" align-self="center">
                            <v-icon color="warning" class="mx-3">
                                circle-exclamation
                            </v-icon>
                        </v-col>
                        <v-col>
                            The selected media is not used in any way, and can
                            be safely deleted.
                            <br />
                            Are you sure you want to delete the selected media?
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-row dense class="mb-4">
                        <v-col cols="auto" align-self="center">
                            <v-icon color="warning" class="mx-3">
                                circle-exclamation
                            </v-icon>
                        </v-col>
                        <v-col>
                            Some of the selected items you are attempting to
                            delete are actively being utilized by Ampifire. As a
                            result, deletion is not permitted at this time.
                            Please ensure that all references to selected items
                            are removed. Once all references have been cleared,
                            you will be able to delete the file.
                        </v-col>
                    </v-row>
                    <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(item, i) in usages" :key="i">
                            <v-expansion-panel-header>
                                <v-row no-gutters>
                                    <v-col cols="auto" class="pr-5">
                                        <v-img
                                            class="rounded"
                                            max-height="60px"
                                            max-width="60px"
                                            :src="item.preview"
                                        >
                                            <template #placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="secondary"
                                                    />
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-col>
                                    <v-col
                                        class="text-caption"
                                        align-self="center"
                                    >
                                        <span v-if="item.usage.length">
                                            Cannot be deleted
                                        </span>
                                        <span v-else>Can be deleted</span>
                                    </v-col>
                                </v-row>
                                <template #actions>
                                    <v-tooltip v-if="item.usage.length" bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                small
                                                color="error"
                                                v-on="on"
                                            >
                                                circle-exclamation
                                            </v-icon>
                                        </template>
                                        Click to see media usage
                                    </v-tooltip>
                                    <v-icon v-else small color="green">
                                        check
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content v-if="item.usage.length">
                                <media-usage
                                    v-for="(usage, j) in item.usage"
                                    :key="j"
                                    :usage="usage"
                                />
                            </v-expansion-panel-content>
                            <v-expansion-panel-content v-else>
                                This media is not used in any way, and can be
                                safely deleted
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <p v-if="canDeleteSome" class="text-center pt-8">
                        You can continue and only delete media that is not in
                        use.
                    </p>
                </div>
            </v-col>
        </v-row>

        <template #actions>
            <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="isLoading"
                @click="close"
            >
                Cancel
            </v-btn>

            <v-spacer />

            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="isLoading || !canDeleteSome"
                @click="confirm"
            >
                Confirm
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

import MediaUsage from './MediaUsage.vue';

import type { FormMediaResource, MediaResourceUsage } from '@/types/Media';

type MediaFileUsage = {
    id: string | number;
    preview: string | null;
    usage: MediaResourceUsage[];
};

const DeleteAccountMediaModalProps = Vue.extend({
    name: 'DeleteAccountMediaModal',
    props: {
        selected: {
            type: Array<FormMediaResource>,
            default() {
                return [];
            }
        }
    }
});

@Component({
    components: { Modal, MediaUsage }
})
export default class DeleteAccountMediaModal extends DeleteAccountMediaModalProps {
    id = 'delete-account-media';

    endpoint = '/media/check_usage';

    isLoading = true;

    canDeleteAll = false;

    canDeleteSome = false;

    usages: MediaFileUsage[] = [];

    get mediaFileIds() {
        return this.selected.map(resource => resource.media_file_id);
    }

    get deletableMediaFileIds() {
        return this.usages.filter(u => !u.usage.length).map(u => u.id);
    }

    onOpen() {
        this.setLoading();

        return this.$http
            .post(this.endpoint, {
                media_file_ids: this.mediaFileIds
            })
            .then(({ data }) => {
                return this.onData(data.data.usages);
            })
            .finally(this.setLoading.bind(this, false));
    }

    onData(usages: Record<number, MediaResourceUsage[]>) {
        this.usages = this.selected.map(resource => {
            return {
                id: resource.media_file_id,
                preview: this.getThumbnail(resource),
                usage: usages[resource.media_file_id]
            };
        });

        this.canDeleteAll = this.usages.every(u => !u.usage.length);

        this.canDeleteSome = this.usages.some(u => !u.usage.length);
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }

    confirm() {
        this.$emit('confirm', this.deletableMediaFileIds);
    }

    getThumbnail(resource: FormMediaResource) {
        return (
            resource.media_file.preview_image ||
            resource.media_file.thumbnail_filename ||
            resource.media_file.relative_filename
        );
    }
}
</script>

<style lang="scss" scoped>
.delete-media--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
