<template>
    <v-row class="results-container">
        <v-col
            v-for="(media, index) in stockMedia"
            :key="`media=${index}`"
            cols="6"
            sm="3"
            class="media-wrapper"
            :class="{ selected: isSelected(media.url) }"
        >
            <div class="actions">
                <v-tooltip v-if="!isSelected(media.url)" bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            icon
                            :ripple="false"
                            class="action--button action-select"
                            :disabled="requesting"
                            v-bind="attrs"
                            v-on="on"
                            @click="$emit('checked', media)"
                        >
                            <v-icon size="20px">square</v-icon>
                        </v-btn>
                    </template>
                    <span>Click to select media</span>
                </v-tooltip>

                <v-btn
                    v-else
                    icon
                    :ripple="false"
                    class="action--button action-deselect"
                    :disabled="requesting"
                    @click="$emit('unchecked', media.url)"
                >
                    <v-icon size="20px">check-square</v-icon>
                </v-btn>
            </div>

            <v-img
                :key="`img-${index}`"
                :src="media.thumbnail"
                class="media-preview"
                @click="preview(media)"
            >
                <template #placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="secondary" />
                    </v-row>
                </template>
            </v-img>
        </v-col>

        <template v-if="fetching">
            <v-col
                v-for="index in Array.from(Array(4).keys())"
                :key="`skeleton-${index}`"
                cols="6"
                sm="3"
                class="media-loading"
            >
                <v-skeleton-loader elevation="2" type="image" height="100%" />
            </v-col>
        </template>

        <media-preview-modal
            v-if="previewMedia"
            :media="previewMedia"
            @close="preview(null)"
        />
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { StockMedia } from '@/types/Media';

import MediaPreviewModal from '../MediaPreviewModal.vue';

const StockSearchMediaResultProps = Vue.extend({
    props: {
        stockMedia: {
            type: Array as PropType<StockMedia[]>,
            default() {
                return [];
            }
        },
        fetching: {
            type: Boolean,
            default() {
                return false;
            }
        },
        selectedMedia: {
            type: Array as PropType<StockMedia[]>,
            default() {
                return [];
            }
        }
    }
});

@Component({
    components: { MediaPreviewModal }
})
export default class StockSearchMediaResult extends StockSearchMediaResultProps {
    previewMedia: StockMedia | null = null;

    @Watch('previewMedia')
    onPreviewMediaChange(v: StockMedia) {
        if (v) {
            this.$store.dispatch('modal/open', 'previewMedia');
        }
    }

    get requesting(): boolean {
        return this.$store.state.loading.show;
    }

    isSelected(url: string) {
        return this.selectedMedia.find(media => media.url === url);
    }

    preview(media: StockMedia | null) {
        this.previewMedia = media;
    }
}
</script>

<style lang="scss" scoped>
.results-container::v-deep {
    align-content: flex-start;
    position: relative;
    margin: 8px 0 0;

    .media-wrapper {
        height: 10em;
        padding-left: 0;
        padding-top: 0;

        .actions {
            position: absolute;
            z-index: 1;

            .action--button {
                .v-icon {
                    background-color: white;
                    border-radius: 2px;
                    width: 16px !important;
                    height: 16px !important;
                }

                &.action-select {
                    .v-icon {
                        svg {
                            stroke: $concrete-solid !important;
                            stroke-width: 8px;
                            filter: drop-shadow(1px 1px 1px black);

                            path {
                                fill: white !important;
                            }

                            border-bottom: solid 1px black;
                            filter: drop-shadow(1px 1px 1px black);
                        }
                    }
                }

                &.action-deselect {
                    .v-icon {
                        svg {
                            stroke: $doger-blue !important;

                            path {
                                fill: $doger-blue !important;
                            }
                        }
                    }
                }
            }
        }

        .media-preview {
            height: 100%;
            cursor: pointer;
            border: $bonjour 2px solid;
            transition: all 0.2s ease;
        }

        &.selected {
            .media-preview {
                border-width: 8px;
                border-color: $faded-blue;
            }
        }
    }

    .media-loading {
        height: 10em;
        padding-left: 0;
        padding-top: 0;
    }
}
</style>
