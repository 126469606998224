import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{attrs:{"id":"user-account-media-preview","title":"Edit Image"},on:{"close":function($event){return _vm.$emit('close')}}},[_c(VContainer,{staticClass:"media-edit--container pa-0"},[_c(VRow,{staticClass:"media-edit--wrapper ma-0"},[_c(VCol,{staticClass:"media-edit--image-wrapper pl-0 py-0",attrs:{"cols":"12","sm":"8"}},[_c(VImg,{staticClass:"media-edit--image",attrs:{"contain":"","src":_vm.mediaResource.media_file.relative_filename},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"secondary"}})],1)]},proxy:true}])})],1),(_vm.resource)?_c(VCol,{staticClass:"media-edit--form-wrapper py-0 mt-4 mt-sm-0 pl-0",attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"filled":"","label":"Title","color":"secondary","name":"resource.media_file.title"},on:{"change":_vm.updateMedia},model:{value:(_vm.resource.media_file.title),callback:function ($$v) {_vm.$set(_vm.resource.media_file, "title", $$v)},expression:"resource.media_file.title"}}),_c(VTextarea,{attrs:{"filled":"","label":"Description","rows":"4","color":"secondary","name":"resource.media_file.description"},on:{"change":_vm.updateMedia},model:{value:(_vm.resource.media_file.description),callback:function ($$v) {_vm.$set(_vm.resource.media_file, "description", $$v)},expression:"resource.media_file.description"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }