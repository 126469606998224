<template>
    <v-container class="media-tab-container">
        <v-row class="stock-container">
            <v-col cols="12" class="media-result-container">
                <v-row class="media-result-wrapper">
                    <v-col
                        v-for="resource in resources"
                        :key="`video-stock-${resource.media_file_id}`"
                        class="media-wrapper"
                        :class="{
                            selected: isSelected(resource.media_file_id)
                        }"
                        cols="6"
                        sm="3"
                    >
                        <div class="actions">
                            <v-tooltip
                                v-if="!isSelected(resource.media_file_id)"
                                bottom
                            >
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        :ripple="false"
                                        class="action-button action-select"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="select(resource)"
                                    >
                                        <v-icon size="20px">square</v-icon>
                                    </v-btn>
                                </template>
                                <span>Click to select media</span>
                            </v-tooltip>

                            <v-btn
                                v-else
                                icon
                                :ripple="false"
                                class="action-button action-deselect"
                                @click="deselect(resource.media_file_id)"
                            >
                                <v-icon size="20px">check-square</v-icon>
                            </v-btn>
                        </div>

                        <v-img
                            contain
                            class="media-preview"
                            :src="sourceFor(resource)"
                        >
                            <template #placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="secondary"
                                    />
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" class="media-tab-footer">
                <v-btn
                    class="media-dialog__confirm-btn px-10"
                    color="primary"
                    large
                    :block="$vuetify.breakpoint.xs"
                    :disabled="selected.length === 0"
                    :loading="loading"
                    @click="confirm"
                >
                    Confirm
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { FormMediaResource } from '@/types/Media';

const InternalStockMediaProps = Vue.extend({
    name: 'InternalStockMedia',
    props: {
        resources: {
            type: Array as PropType<FormMediaResource[]>,
            default() {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class InternalStockMedia extends InternalStockMediaProps {
    selected: FormMediaResource[] = [];

    select(resource: FormMediaResource) {
        this.selected = [resource];
    }

    getSelectedResourceIndex(mediaFileId: FormMediaResource['media_file_id']) {
        return this.selected.findIndex(
            resource => resource.media_file_id === mediaFileId
        );
    }

    deselect(mediaFileId: FormMediaResource['media_file_id']) {
        const index = this.getSelectedResourceIndex(mediaFileId);

        if (~index) {
            this.selected.splice(index, 1);
        }
    }

    isSelected(mediaFileId: FormMediaResource['media_file_id']) {
        return Boolean(~this.getSelectedResourceIndex(mediaFileId));
    }

    save() {
        this.$emit('saved', this.selected);
    }

    collect() {
        this.$emit('collected', this.selected);
    }

    reset() {
        this.selected = [];
    }

    confirm() {
        this.$emit('confirm');
    }

    sourceFor(resource: FormMediaResource) {
        return resource.media_file.type === 0
            ? resource.media_file.relative_filename
            : resource.media_file.preview_image;
    }
}
</script>

<style lang="scss" scoped>
.media-tab-container::v-deep {
    height: 100%;

    .stock-container {
        height: 100%;
        align-content: flex-start;

        .media-result-container {
            height: calc(100% - 2.5em);
            border: 2px dashed $bonjour;
            border-radius: 4px;
            overflow: hidden;
            padding: 20px 20px 0 20px;

            .media-result-wrapper {
                display: flex;
                align-content: flex-start;
                height: 100%;
                overflow: auto;
                position: relative;

                .media-wrapper {
                    height: 10em;
                    padding-left: 0;
                    padding-top: 0;

                    .actions {
                        position: absolute;
                        z-index: 1;

                        .action-button {
                            .v-icon {
                                background-color: white;
                                border-radius: 2px;
                                width: 16px !important;
                                height: 16px !important;
                            }

                            &.action-select {
                                .v-icon {
                                    svg {
                                        stroke: $concrete-solid !important;
                                        stroke-width: 8px;
                                        filter: drop-shadow(1px 1px 1px black);

                                        path {
                                            fill: white !important;
                                        }
                                    }
                                }
                            }

                            &.action-deselect {
                                .v-icon {
                                    svg {
                                        stroke: $doger-blue !important;

                                        path {
                                            fill: $doger-blue !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .media-preview {
                        height: 100%;
                        cursor: pointer;
                        border: $bonjour 2px solid;
                        transition: all 0.2s ease;
                        // stock media could be white
                        // so we need a backgroud to make them visible
                        background-color: $seashell-solid;
                    }

                    &.selected {
                        .media-preview {
                            border-width: 8px;
                            border-color: $faded-blue;
                        }
                    }
                }
            }
        }

        .media-tab-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 20px 24px;
        }
    }
}
</style>
